import React from 'react'
import './Gallery.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from 'react-router-dom'

function GalleryNavBar() {
  return (
    <>
      <Navbar className="navbarColor" expand="lg">
        <Container>
            <Navbar.Brand><h3 className="galleryNightzillla">Nightzillla</h3></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto navbarHeadings">
                <Link className='text-link'to="/"><h5 className="navbarText">Home</h5></Link>
                <Link className='text-link'to="/aboutme"><h5 className="navbarText">Profile</h5></Link>
                <Link className='text-link'to="/cohort"><h5 className="navbarText">Cohort</h5></Link>
            </Nav>
            </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default GalleryNavBar