import React, { useState, useEffect }from 'react'
import Smoke from '../../video/smoke.mp4'
import './HomePage.css'
import HomePageContent from './HomePageContent'
// import NavBar from '../../components/NavBar'
function HomePage() {
  const [hideVideo, setHideVideo] = useState(false);
  const [showWelcomeHeader, setShowWelcomeHeader] = useState(true);
  const [showVideo, setShowVideo] = useState(true);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowVideo(false);
    }, 2000);

    const hideVideo = setTimeout(() => {
      setHideVideo(true);
    }, 3500);

    const welcomeHeaderTimer = setTimeout(() => {
      setShowWelcomeHeader(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
      clearTimeout(welcomeHeaderTimer);
      clearTimeout(hideVideo);
    };
  }, []);

  useEffect(() => {
    if (!showVideo) {
      const contentTimer = setTimeout(() => {
        setShowContent(true);
      }, 100);

      return () => {
        clearTimeout(contentTimer);
      };
    }
  }, [showVideo]);

  return (
    <>
      <div className="loading-wrapper">
        <div className={`video-container ${showVideo ? 'fade-in' : 'fade-out'} ${hideVideo ? 'hidden' : 'notHidden'}`}
          style={{ opacity: showVideo ? 1 : 0 }}>
          <video autoPlay muted className={`smoke ${hideVideo ? 'hidden' : ''}`}>
            <source src={Smoke}/>
          </video>
        </div>
        {showWelcomeHeader && 
          (<h1
              className="welcomeHeader"
              style={{ opacity: showWelcomeHeader ? 1 : 0 }}>
              Welcome to Nightzilla
            </h1>)}
        {showContent && <HomePageContent />}
      </div>
    </>
  );
}

export default HomePage;