import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from 'react-router-dom'
import './Cohort.css'

function NavBar() {

    // const [click, setClick] = useState(false);
    
    // const handlerChange = (e) => {
        
    //     setClick((preState)=> !preState)
    
      return (
        <Navbar className="navbarColor-Cohort" expand="lg">
          <Container>
              <Navbar.Brand><h3 className="homePageNightzillla">Nightzillla</h3></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto navbarHeadings">
                  <Link className='text-link'to="/"><h5 className="AboutMeText">Home</h5></Link>
                  <Link className='text-link'to="/aboutme"><h3 className="GalleryText">Profile</h3></Link>
                  <Link className='text-link'to="/gallery"><h3 className="cohortGalleryText">Gallery</h3></Link>
              </Nav>
              </Navbar.Collapse>
          </Container>
        </Navbar>
      )
    }
export default NavBar