import React from 'react'
import './Gallery.css'
import './GalleryNavBar'
import World from '../../img/Gallery/world.jpg'
import Sedona from '../../img/Gallery/sedona.jpg'
import NewMexico from '../../img/Gallery/new-mexico.jpg'
import Inception from '../../img/Gallery/inception.jpg'
import HTown from '../../img/Gallery/h-town.jpg'
import Pearl from '../../img/Gallery/pearl.jpg'
import Stanely from '../../img/Gallery/Stanely.jpg'
import Stairs from '../../img/Gallery/stairs.jpg'
import Hurricane from '../../img/Gallery/hurricane.jpg'
import GalleryNavBar from './GalleryNavBar'
import LoneStar from '../../img/Gallery/lonestar.jpg'
import Portland from '../../img/Gallery/portland.png'
import Smokeb from '../../img/Gallery/SmokeB.jpg'

function Gallery() {
  return (
    <>
      <div className='gallery-page-wrapper'>
        <GalleryNavBar/>
        <div className="img-container">
            <div className="wrapper">
              <div className="img-card">
                <img src={World}/>
              </div>
            </div>
            <div className="wrapper">
              <div className="img-card">
                <img src={Sedona}/>
              </div>
            </div>
            <div className="wrapper">
              <div className="img-card">
                <img src={NewMexico}/>
              </div>
            </div>
            <div className="wrapper">
              <div className="img-card">
                <img src={Pearl}/>
              </div>
            </div>
            <div className="wrapper">
              <div className="img-card">
                <img src={Smokeb}/>
              </div>
            </div>
            <div className="wrapper">
              <div className="img-card">
                <img src={Inception}/>
              </div>
            </div>
            <div className="wrapper">
              <div className="img-card">
                <img src={HTown}/>
              </div>
            </div>
            <div className="wrapper">
              <div className="img-card">
                <img src={Stairs}/>
              </div>
            </div>
            <div className="wrapper">
              <div className="img-card">
                <img src={Hurricane}/>
              </div>
            </div>
            <div className="wrapper">
              <div className="img-card">
                <img src={Portland}/>
              </div>
            </div>
            <div className="wrapper">
              <div className="img-card">
                <img src={LoneStar}/>
              </div>
            </div>
            <div className="wrapper">
              <div className="img-card">
                <img src={Stanely}/>
              </div>
            </div>
          </div>{/* END OF IMG-CONTENT*/}
      </div>{/* END OF GALLERY PAGE WRAPPER */}
    </>
  )
}

export default Gallery