import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from 'react-router-dom'
import './HomePageNavBar.css'
// import NavDropdown from 'react-bootstrap/NavDropdown';

function HomePageNavBar() {
  return (
    <div>
        <>
        <Navbar className="navbarColor" expand="lg">
            <Container>
                <Navbar.Brand><h3 className="homePageNightzillla">Nightzillla</h3></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto navbarHeadings">
                    <Link className='text-link'to="aboutme"><h5 className="AboutMeText">Profile</h5></Link>
                    <Link className='text-link'to="gallery"><h5 className="GalleryText">Gallery</h5></Link>
                    <Link className='text-link'to="cohort"><h5 className="cohortText">Cohort</h5></Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        </>
    </div>
  )
}
export default HomePageNavBar